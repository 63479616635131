import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Quick Tips on Recruiters (The non-military kind).",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "quick-tips-on-recruiters-the-non-military-kind",
  "draft": false,
  "meta_title": "Quick Tips on Recruiters (The non-military kind)."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Whether you started your military career in a recruiter's office or not, you get the idea. You know the process. You know that the recruiter's job is to onboard candidates that are a good fit for the military life. There are questions. There is an interview. There are physical standards to be met. They are your initial step in the military sector.`}</p>
    <p>{`While a recruiter in a corporate setting function in a similar capacity, they have very little to do with the hiring process other than qualifying you as a candidate for the company they work for. So what exactly does a recruiter do in the civilian world? Do they sit in storefront offices and wait for people to drop in and ask about employment?`}</p>
    <p>{`Fast forward 20-30 years when your time comes to retire and transition into a civilian job, you'll be taking on recruiters of a different kind. Corporate recruiters function on a very different wavelength with very different parameters and goals. Here are the top 5 things that you should know:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`A corporate recruiter is not a headhunter`}</strong>{`. Different jobs. Different roles in the job marketplace. A recruiter normally specializes in one industry or in one particular company. They are involved in a broad spectrum of human resource capabilities for their company. A headhunter, on the other hand, usually seeks out talent for one specific job and can be contracted by many different companies at once.`}</li>
      <li parentName="ol"><strong parentName="li">{`Recruiters are the connection between YOU (the candidate) and the hiring manager.`}</strong>{` They seek to make the best matches for their company, pass those candidates onto hiring managers who then conduct interviews and make job offers. The recruiter won't be a part of the hiring process past connecting you to the hiring manager.`}</li>
      <li parentName="ol"><strong parentName="li">{`A recruiter is not there to help guide you in your career transition or active seeking process of employment.`}</strong>{` They are paid by a company to find candidates to fulfill jobs within their organization. So while a recruiter may help you land a job within their company, they generally won't make referrals, recommendations, or mentor you in a general sense.`}</li>
      <li parentName="ol"><strong parentName="li">{`A recruiter will spend a VERY limited amount of time on your resume.`}</strong>{` So make it concise, easily formatted, and a strong reflection of you who are as an individual! `}<a parentName="li" {...{
          "href": "http://www.purepost.co"
        }}>{`Purepost`}</a>{` can assist you in completing your resume as a military veteran entering the civilian sector. Having your military experience translated in a way that a recruiter, hiring manager, or future employer understands is critical. Recruiters are looking at up to a thousand resumes depending on the position, so give your resume the 30-second test. Can somebody not familiar with the military get a good picture of who you are by looking at your resume in 30 seconds?`}</li>
      <li parentName="ol"><strong parentName="li">{`Ask questions. This is your career and your future.`}</strong>{` "Once you have my resume, when should I expect to hear from you again?" or "Will you ever submit my resume to a company without my approval?" are questions that a professional should have no issues answering and should welcome from a prepared, professional candidate.`}</li>
    </ol>
    <p>{`We look forward to providing additional tips and content on recruiters, the process, and how to navigate your way through your career transition. If you have any specific requests or insights, please email us at `}<a parentName="p" {...{
        "href": "mailto:blogs@purepost.co"
      }}>{`blogs@purepost.co`}</a>{`.`}</p>
    <p>{`~The Purepost Team`}</p>
    <p>{`Sources:`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/pulse/20140529005527-20396070-what-does-a-recruiter-actually-do"
      }}>{` `}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/pulse/20140529005527-20396070-what-does-a-recruiter-actually-do"
      }}>{`What Does A Recruiter Actually Do?`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://www.huffingtonpost.com/susan-p-joyce/job-recruiters_b_5059365.html"
      }}>{`5 Things You Should Know About Job Recruiters`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "http://fortune.com/2015/03/12/at-first-glance-what-do-recruiters-looks-for-in-a-resume/"
      }}>{`At First Glance`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      